import SwiperCore, { Mousewheel } from "swiper"
import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import { Swiper, SwiperSlide } from "swiper/react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Import Swiper styles
// import "swiper/swiper.min.css"
import "swiper/css"
SwiperCore.use([Mousewheel])

const Section = styled.section`
  ${tw`relative z-10 py-10 px-offset sm:px-12 lg:px-16 xl:px-24`}
`
const CarouselWrap = styled.div`
  ${tw`relative z-10`}
  & img {
    ${tw`object-contain w-full`}
  }
`

const WpAcfSliderRefBlock = ({ moduleData }) => {
  const blockData = moduleData.acfRefSliderBlock
  const sectionAttributes = moduleData.attributes
  const sectionId = sectionAttributes.anchor || ""
  return (
    <Section id={sectionId}>
      <CarouselWrap
        className={`${
          blockData.slides.length > 1 ? "aspect-w-16 aspect-h-9" : ""
        }`}
      >
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          // breakpoints={{
          //     1400: {
          //         mousewheel: false
          //     }
          // }}
          scrollbar={{ draggable: true }}
        >
          {blockData.slides.map((slide, i) => {
            const image = getImage(slide.localFile)
            return (
              <SwiperSlide key={`refslide-${i}`}>
                <GatsbyImage
                  objectFit="cover"
                  className="w-full"
                  image={image}
                  alt=""
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </CarouselWrap>
    </Section>
  )
}

WpAcfSliderRefBlock.propTypes = {
  moduleData: PropTypes.object,
}

WpAcfSliderRefBlock.defaultProps = {
  moduleData: ``,
}

export default WpAcfSliderRefBlock
